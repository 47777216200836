@media (min-width: 992px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c1508'] {
        display: none;
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c150a'] {
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c1537'] {
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c153c'] {
        opacity: 0;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c1508'] {
        display: none;
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c150a'] {
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c1537'] {
        opacity: 0;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id='f06aae9d-7c63-331c-4228-b398627c153c'] {
        opacity: 0;
    }
}

h1,
h2,
h3 {
    overflow-wrap: break-word;
}

ul {
    overflow-wrap: break-word;
}

p {
    overflow-wrap: break-word;
}

:where(),
a {
    color: inherit;
}

:root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px;
}

html :where(img) {
    height: auto;
    max-width: 100%;
}

.theme-twentytwenty .wc-block-components-product-price .woocommerce-Price-amount,
.theme-twentytwenty .wc-block-grid__product-price .woocommerce-Price-amount {
    font-family: -apple-system, blinkmacsystemfont, Helvetica Neue, helvetica, sans-serif;
    font-size: 0.9em;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidein {
    0% {
        transform: translateX(0);
    }

    to {
        transform: translateX(-480px);
    }
}

@keyframes slidein {
    0% {
        transform: translateX(0);
    }

    to {
        transform: translateX(-480px);
    }
}

@media only screen and (max-width: 480px) {
    @-webkit-keyframes slidein {
        0% {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100vw);
        }
    }

    @keyframes slidein {
        0% {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100vw);
        }
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1 turn);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1 turn);
    }
}

@-webkit-keyframes spinner__animation {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
        animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1 turn);
    }
}

@keyframes spinner__animation {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
        animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1 turn);
    }
}

@-webkit-keyframes loading__animation {
    to {
        transform: translateX(100%);
    }
}

@keyframes loading__animation {
    to {
        transform: translateX(100%);
    }
}

body {
    --wp--preset--color--black: #000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #fff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, #9b51e0 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, #7adcb4 0%, #00d082 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
        135deg,
        rgba(252, 185, 0, 1) 0%,
        rgba(255, 105, 0, 1) 100%
    );
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, #cf2e2e 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, #eee 0%, #a9b8c3 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
        135deg,
        #4aeadc 0%,
        #9778d1 20%,
        #cf2aba 40%,
        #ee2c82 60%,
        #fb6962 80%,
        #fef84c 100%
    );
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, #ffceec 0%, #9896f0 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, #caf880 0%, #71ce7e 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, #020381 0%, #2874fc 100%);
    --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
    --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
    --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
    --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
    --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
    --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
    --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
    --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
}

@font-face {
    font-display: swap;
    font-family: 'slick';
    font-weight: 400;
    font-style: normal;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

.slick-prev:before {
    content: '←';
}

.slick-next {
    right: -25px;
}

.slick-next:before {
    content: '→';
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

@media (max-width: 1422px) {
    .slick-dots {
        bottom: -25px;
    }
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.7;
    color: #98bf38;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.twentytwenty-after-label,
.twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-after-label,
.twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-before-label {
    opacity: 0;
}

.twentytwenty-after-label,
.twentytwenty-before-label,
.twentytwenty-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
}

.twentytwenty-after-label,
.twentytwenty-before-label {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
}

.twentytwenty-after-label:before,
.twentytwenty-before-label:before {
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.1em;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    line-height: 38px;
    padding: 0 20px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.twentytwenty-container.active .twentytwenty-overlay,
.twentytwenty-container.active :hover.twentytwenty-overlay,
.twentytwenty-overlay {
    background: rgba(0, 0, 0, 0);
}

.twentytwenty-container {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 0;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.twentytwenty-container img {
    max-width: 100%;
    position: absolute;
    top: 0;
    display: block;
}

.twentytwenty-container * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.twentytwenty-before-label:before {
    content: 'Before';
}

.twentytwenty-after-label:before {
    content: 'After';
}

.twentytwenty-overlay {
    -webkit-transition-property: background;
    -moz-transition-property: background;
    transition-property: background;
    z-index: 25;
}

.twentytwenty-overlay:hover {
    background: rgba(0, 0, 0, 0.5);
}

.twentytwenty-overlay:hover .twentytwenty-after-label,
.twentytwenty-overlay:hover .twentytwenty-before-label {
    opacity: 1;
}

.twentytwenty-before {
    z-index: 20;
}

.twentytwenty-after {
    z-index: 10;
}

.twentytwenty-handle {
    height: 38px;
    width: 38px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
    border: 3px solid #fff;
    -webkit-border-radius: 1000px;
    -moz-border-radius: 1000px;
    border-radius: 1000px;
    -webkit-box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
    box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
    z-index: 40;
    cursor: pointer;
}

select {
    width: 100%;
}

*,
:after,
:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    font-size: 100%;
}

body {
    background: #fff;
    color: #222;
    padding: 0;
    margin: 0;
    font-family: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    position: relative;
    cursor: default;
}

a:hover {
    cursor: pointer;
}

a:focus {
    outline: 0;
}

img,
object {
    max-width: 100%;
    height: auto;
}

object {
    height: 100%;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

img {
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
}

textarea {
    height: auto;
    min-height: 50px;
}

@media only screen {
    [class*='column'] + [class*='column']:last-child {
        float: right;
    }
}

.checkout-box.pay {
    display: none;
}

* {
    line-height: 120%;
}

.slide_cat_btn:hover .arrow_img {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.productslider_nav .slick-dots {
    display: flex;
    position: static;
}

.productslider_nav .slick-dots li {
    width: 100%;
    height: 1px;
}

.productslider_nav .slick-dots li button {
    width: 100%;
    height: 1px;
    padding: 0;
}

.productslider_nav .slick-dots li.slick-active button {
    width: 100%;
    height: 1px;
    background: #4d4285;
}

.productslider_nav .slick-dots li button:before {
    display: none;
}

.productslider_nav .slick-disabled,
.reviewslider .slick-disabled {
    opacity: 0.5;
    cursor: auto;
    box-shadow: none;
}

.bestsslide .slick-list,
.bestsslide .slick-track,
.bestsslide .slick-slide > div {
    height: 100%;
}

.mainslider .slick-dots li {
    width: auto;
    height: auto;
}

.mainslider .slick-dots li button:before {
    display: none;
}

.mainslider .slick-dots li button {
    width: 8px;
    height: 8px;
    border: 1px solid #8b7ec9;
    border-radius: 100%;
    padding: 4px;
}

.mainslider .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;
    background: #4d4285;
    border: 1px solid #8b7ec9;
    border-radius: 100%;
    padding: 2px;
}

.productslider .slick-track {
    display: flex;
}

.productslider .slick-slide {
    height: auto !important;
}

.productslider .slick-slide > div,
.productslider .slick-slide .slide {
    height: 100%;
}

.mini_cart_items,
.navbox {
    overflow-y: auto;
    overflow-x: hidden;
}

.mini_cart_items,
.navbox {
    scrollbar-color: #8b7ec9 #fafafa;
    scrollbar-width: thin;
}

.mini_cart_items::-webkit-scrollbar,
.navbox::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: #fafafa;
}

.mini_cart_items::-webkit-scrollbar-thumb,
.navbox::-webkit-scrollbar-thumb {
    background-color: #8b7ec9;
    border-radius: 3px;
    box-shadow: none;
}

.mini_cart_items::-webkit-scrollbar-thumb:hover,
.navbox::-webkit-scrollbar-thumb:hover {
    background-color: #8b7ec9;
}

.slide-product_desc p:not(.truncated) ~ span,
.slide-product_desc p:not(.truncated) ~ div {
    display: none;
}

.twentytwenty-before-label:before {
    content: 'До' !important;
}

.twentytwenty-after-label:before {
    content: 'После' !important;
}

.twentytwenty-handle {
    background: #4d4285 !important;
    border: none !important;
}

input[type='radio']:checked ~ span {
    border: none;
}

@media screen and (max-width: 767px) {
    .woocommerce-Price-amount.amount {
        margin-left: 5px;
    }
}

@font-face {
    font-display: swap;
    font-family: 'rub-arial-regular';
    src: url(https://1790009369.rsc.cdn77.org/assets/ruble-simb.woff), url(https://1790009369.rsc.cdn77.org/assets/ruble-simb.ttf);
}

.rur {
    font-family: 'rub-arial-regular';
    text-transform: uppercase;
    src: url(https://1790009369.rsc.cdn77.org/assets/ruble-simb.woff), url(https://1790009369.rsc.cdn77.org/assets/ruble-simb.ttf);
}

.rur span {
    display: none;
}
